import {
  motion,
  MotionValue,
  PanInfo,
  useSpring,
  useTransform,
} from 'motion/react';
import {
  Children,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import useMeasure from 'react-use-measure';

const DRAG_BUFFER = 50;
export default function Slideshow({
  children,
  className,
  infinite = false,
  slide,
  onSlideChange,
}: {
  children: ReactNode[];
  className?: string;
  infinite?: boolean;
  slide?: number;
  onSlideChange?: (slide: number) => void;
}) {
  const [internalSlide, setSlide] = useState(slide ?? 0);
  const [internalUpdate, setInternalUpdate] = useState(false);
  const [childSize, setChildSize] = useState({ width: 0, height: 0 });
  const [ref, bounds] = useMeasure();
  const animatedValue = useSpring(internalSlide, { bounce: 0 });
  const childrenArray = useMemo(() => Children.toArray(children), [children]);

  useEffect(() => {
    animatedValue.set(internalSlide);
  }, [animatedValue, internalSlide]);

  const onDragEnd = useCallback(
    (e: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
      const x = info.offset.x;

      if (
        x < -DRAG_BUFFER &&
        (infinite || internalSlide < childrenArray.length - 1)
      ) {
        // User swiped left, increment internalSlide
        setSlide(pv =>
          infinite ? pv + 1 : Math.min(childrenArray.length - 1, pv + 1)
        );
        setInternalUpdate(true);
      } else if (x > DRAG_BUFFER && (infinite || internalSlide > 0)) {
        // User swiped right, decrement internalSlide
        setSlide(pv => (infinite ? pv - 1 : Math.max(0, pv - 1)));
        setInternalUpdate(true);
      }
    },
    [internalSlide, childrenArray.length, infinite]
  );

  useEffect(() => {
    if (typeof slide !== 'undefined') {
      if (slide !== internalSlide && !internalUpdate) {
        setSlide(slide ?? 0);
      }
    }
  }, [slide, internalSlide, internalUpdate]);

  useEffect(() => {
    if (internalUpdate) {
      onSlideChange?.(internalSlide);
      setInternalUpdate(false);
    }
  }, [internalSlide, onSlideChange, internalUpdate]);

  const onReportSize = useCallback(
    (newSize: { width: number; height: number }) => {
      if (Math.ceil(newSize.height) > Math.ceil(childSize.height)) {
        setChildSize(newSize);
      }
    },
    [childSize]
  );

  return (
    <motion.div
      ref={ref}
      className={`relative flex justify-start overflow-x-hidden ${className}`}
      style={{ height: childSize.height }}
    >
      {childrenArray.map((child, index) => (
        <Slide
          key={`slide-${index}`}
          mv={animatedValue}
          index={index}
          slideCount={childrenArray.length}
          parentWidth={bounds.width}
          onDragEnd={onDragEnd}
          onReportSize={onReportSize}
        >
          {child}
        </Slide>
      ))}
    </motion.div>
  );
}

function Slide({
  mv,
  index,
  slideCount,
  parentWidth,
  children,
  onDragEnd,
  onReportSize,
}: {
  mv: MotionValue;
  index: number;
  slideCount: number;
  children: ReactNode;
  parentWidth: number;
  onReportSize?: (size: { width: number; height: number }) => void;
  onDragEnd?: (
    e: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo
  ) => void;
}) {
  const [ref, bounds] = useMeasure();
  const x = useTransform(mv, latest => {
    const placeValue = latest % slideCount;
    const offset = (slideCount + index - placeValue) % slideCount;

    let memo = offset * parentWidth;

    if (offset > slideCount / 2) {
      memo -= slideCount * parentWidth;
    }

    return memo;
  });

  useLayoutEffect(() => {
    if (bounds.height && bounds.width) {
      onReportSize?.(bounds);
    }
  }, [bounds, onReportSize]);

  return (
    <motion.div
      style={{ x }}
      drag="x"
      dragConstraints={{
        left: 0,
        right: 0,
      }}
      onDragEnd={onDragEnd}
      dragElastic={0.08}
      className="absolute inset-0 flex flex-1 cursor-grab items-center justify-center active:cursor-grabbing"
    >
      <div ref={ref} className="flex flex-1 items-center justify-center">
        {children}
      </div>
    </motion.div>
  );
}
