import {
  CssBaseline,
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material';
import { themeOptions } from '../theme';

function GlobalThemeProvider({ overwriteTheme, children }) {
  return (
    <MuiThemeProvider theme={createTheme(themeOptions, overwriteTheme)}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}

export { GlobalThemeProvider };
