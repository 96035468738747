import { AspectRatio } from '../../../components';
import { Button } from '../../../ui';
import Imgix from 'react-imgix';

export type PersonalizedCard = {
  title: string;
  description: string;
  url: string;
};

export function PersonalizedSecurity({
  title = 'Personalized security in 3 simple steps.',
  buttonLabel = 'Customize and Check Out',
  buttonHref = '/quiz',
  cards = defaultCards,
}: {
  title?: string;
  buttonLabel?: string;
  buttonHref?: string;
  cards?: PersonalizedCard[];
}) {
  return (
    <section className="mx-auto w-full max-w-screen-xxl bg-cove-navy-blue px-cove-25 pb-cove-100 pt-cove-75 text-white md:px-cove-50 lg:px-cove-137">
      <h2 className="w-full text-center text-h2 font-bold">{title}</h2>
      <div className="mx-auto mt-cove-75 grid max-w-[350px] grid-cols-1 justify-between gap-cove-50 sm:max-w-none sm:grid-cols-3 sm:gap-cove-25 md:gap-cove-50">
        {cards.map((card, index) => (
          <div key={card.title} className="relative w-full">
            <div className="absolute left-1/2 top-0 z-10 flex size-[40px] -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-cove-green text-lg font-semibold text-white sm:size-cove-50">
              {index + 1}
            </div>
            <AspectRatio ratio={350 / 250}>
              <Imgix
                src={card.url}
                htmlAttributes={{ alt: card.title }}
                width={350}
                height={250}
                className="h-auto w-full object-cover"
              />
            </AspectRatio>
            <h3 className="mb-cove-15 mt-cove-35 text-base font-semibold sm:text-lg sm:font-semibold">
              {card.title}
            </h3>
            <p>{card.description}</p>
          </div>
        ))}
      </div>
      <Button
        variant="outlined"
        href={buttonHref}
        className="mx-auto mt-cove-50 text-center"
      >
        {buttonLabel}
      </Button>
    </section>
  );
}

const defaultCards = [
  {
    title: 'Customize your package',
    description:
      'We’ll help you choose the perfect equipment for a security system as unique as your home.',
    url: 'https://www.datocms-assets.com/10154/1743185154-alarm-system-package.png',
  },
  {
    title: 'Install it in minutes',
    description:
      'Self-installation takes less than 30 minutes. Now offering professional installation as well.',
    url: 'https://www.datocms-assets.com/10154/1743185154-woman-installing-window-sensor.png',
  },
  {
    title: 'Count on us',
    description:
      'With three 24/7 professional monitoring stations, your home will always be protected.',
    url: 'https://www.datocms-assets.com/10154/1743185155-woman-interacting-with-alarm-panel.png',
  },
];
