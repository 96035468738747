import { cn, useMergeRef } from '@shared/cove-ui';
import { AnimatePresence, motion } from 'framer-motion';
import { forwardRef } from 'react';
import useMeasure from 'react-use-measure';
import { ResizablePanelProps } from './resizable-panel-constants';

const ResizablePanel = forwardRef<HTMLDivElement, ResizablePanelProps>(
  (
    {
      children,
      className,
      wrapperClassName,
      wrapperProps,
      fade = false,
      initial = false,
      mode,
      scale = false,
      ...props
    },
    forwardedRef
  ) => {
    let [ref, { height }] = useMeasure({ offsetSize: scale });
    let mergedRef = useMergeRef(ref, forwardedRef);

    return (
      <motion.div
        animate={{ height }}
        className={cn('relative overflow-hidden', wrapperClassName)}
        {...wrapperProps}
      >
        <AnimatePresence initial={initial} mode={mode}>
          <motion.div
            key={JSON.stringify(children, ignoreCircularReferences())}
            initial={{
              opacity: fade ? 0 : 1,
              scale: scale ? 0 : 1,
            }}
            animate={{
              opacity: 1,
              scale: 1,
            }}
            exit={{
              opacity: fade ? 0 : 1,
              scale: scale ? 0 : 1,
            }}
          >
            <div
              ref={mergedRef}
              className={cn(
                `${height > 0 ? 'absolute' : 'relative'}`,
                className
              )}
              {...props}
            >
              {children}
            </div>
          </motion.div>
        </AnimatePresence>
      </motion.div>
    );
  }
);

/*
  Replacer function to JSON.stringify that ignores
  circular references and internal React properties.
  https://github.com/facebook/react/issues/8669#issuecomment-531515508
*/
const ignoreCircularReferences = () => {
  const seen = new WeakSet();
  return (key: string, value: any) => {
    if (key.startsWith('_')) return; // Don't compare React's internal props.
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) return;
      seen.add(value);
    }
    return value;
  };
};

export default ResizablePanel;
