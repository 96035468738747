import { useState } from 'react';
import { cn, Slideshow } from '../../../../';
import Imgix from 'react-imgix';

export function FeatureHighlights({
  featureCards = defaultCards,
}: {
  featureCards?: typeof defaultCards;
}) {
  const [slide, setSlide] = useState(0);
  return (
    <section className="mx-auto flex w-full max-w-screen-xxl gap-cove-50 bg-cove-dove py-cove-75 md:px-cove-50 lg:px-cove-137">
      <div className="hidden items-start justify-between gap-cove-50 md:flex">
        {featureCards.map(card => (
          <div key={card.title} className="w-full">
            <Imgix src={card.icon} width={100} height={100} />
            <p className="mb-cove-15 mt-cove-50 text-lg font-medium">
              {card.title}
            </p>
            <p>{card.description}</p>
          </div>
        ))}
      </div>
      <div className="flex w-full flex-col md:hidden">
        <Slideshow slide={slide} onSlideChange={setSlide} className="w-full">
          {featureCards.map(card => (
            <div
              key={card.title}
              className="flex w-full flex-col items-center justify-center px-cove-25"
            >
              <Imgix src={card.icon} width={100} height={100} />
              <p className="mb-cove-15 mt-cove-50 text-lg font-medium sm:text-start sm:text-base">
                {card.title}
              </p>
              <p className="text-center">{card.description}</p>
            </div>
          ))}
        </Slideshow>
        <div className="mt-cove-25 flex items-center justify-center gap-cove-5">
          {featureCards.map((_, i) => (
            <button
              key={i}
              onClick={() => setSlide(i)}
              className={cn(
                'relative h-cove-5 w-4 rounded-full border border-cove-navy-blue bg-white transition-all after:absolute after:-inset-1 after:content-[""]',
                slide === i ? 'bg-cove-navy-blue' : ''
              )}
            ></button>
          ))}
        </div>
      </div>
    </section>
  );
}

const defaultCards = [
  {
    title: 'No contracts',
    description:
      'We believe customer loyalty comes from good experiences, not 5-year commitments.',
    icon: 'https://www.datocms-assets.com/10154/1660331803-no-contracts-icon.png',
  },
  {
    title: '60-day free trial',
    description:
      'If you don’t love your system, send it back within 60 days for a full refund (including shipping).',
    icon: 'https://www.datocms-assets.com/10154/1660331858-60-day-free-trial-icon.png',
  },
  {
    title: 'Lifetime equipment warranty',
    description:
      'Not that we think you’ll need it. But it’s always good to know you’ve got it.',
    icon: 'https://www.datocms-assets.com/10154/1660331886-lifetime-equipment-warranty-icon.png',
  },
];
