import { cn } from '../../../../';
import { CSSProperties, HTMLAttributes } from 'react';

export function FadedImageBanner({
  backgroundImages = defaultImages,
  className,
  ...props
}: {
  backgroundImages?: { desktop: string; tablet: string; mobile: string };
} & HTMLAttributes<HTMLDivElement>) {
  return (
    <section
      style={
        {
          '--desktop-image': `url('${backgroundImages.desktop}')`,
          '--tablet-image': `url('${backgroundImages.tablet}')`,
          '--mobile-image': `url('${backgroundImages.mobile}')`,
        } as CSSProperties
      }
      className={`relative mx-auto flex w-full max-w-screen-xxl items-center bg-cover bg-center bg-no-repeat pb-[calc(100%*calc(485/320))] [background-image:var(--mobile-image)] sm:min-h-[450px] sm:pb-0 sm:[background-image:var(--tablet-image)] lg:[background-image:var(--desktop-image)]`}
    >
      <div className="absolute bottom-1/2 left-0 right-0 top-0 bg-gradient-to-b from-black/50 to-transparent sm:bottom-0 sm:right-1/2 sm:bg-gradient-to-r"></div>
      <div
        className={cn(
          'absolute inset-0 flex w-full items-start px-cove-25 py-cove-50 text-white sm:items-center md:px-cove-75 lg:px-cove-137',
          className
        )}
        {...props}
      ></div>
    </section>
  );
}

const defaultImages = {
  desktop:
    'https://www.datocms-assets.com/10154/1689020607-eufy-doorbell-camera.png',
  tablet:
    'https://www.datocms-assets.com/10154/1689020607-eufy-doorbell-camera.png',
  mobile:
    'https://www.datocms-assets.com/10154/1743617487-eufy-doorbell-camera-on-brick-wall.png',
};
