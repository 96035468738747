import { motion } from 'motion/react';
import { useState } from 'react';
import Imgix from 'react-imgix';
import { Icon } from '../../../../';
import { AspectRatio } from '../../../components/aspect-ratio';

const SLIDE_CLICK_DELAY = 200;

export function CarouselHero({
  images = defaultImages,
}: {
  images?: string[];
}) {
  const [carouselImages, setCarouselImages] = useState(images);
  const [canSlide, setCanSlide] = useState(true);

  const goToSlide = (index: number) => {
    setCarouselImages(prev => {
      // swap first image with clicked image
      const firstImage = prev[0];
      const clickedImage = prev[index];
      return [
        clickedImage,
        ...prev.slice(1, index),
        ...prev.slice(index + 1),
        firstImage,
      ];
    });
  };
  const handleNextImage = () => {
    if (!canSlide) return;
    setCarouselImages(prev => {
      const firstImage = prev.shift() as string;
      return [...prev, firstImage];
    });
    setCanSlide(false);
    setTimeout(() => setCanSlide(true), SLIDE_CLICK_DELAY);
  };

  const handlePrevImage = () => {
    if (!canSlide) return;
    setCarouselImages(prev => {
      const lastImage = prev.pop() as string;
      return [lastImage, ...prev];
    });
    setCanSlide(false);
    setTimeout(() => setCanSlide(true), SLIDE_CLICK_DELAY);
  };

  return (
    <div className="w-full">
      <AspectRatio className="relative">
        <button
          className="absolute left-cove-10 top-1/2 z-10 flex size-9 -translate-y-1/2 items-center justify-center rounded-full bg-transparent p-1 sm:left-cove-25"
          onClick={handlePrevImage}
        >
          <div className="flex items-center justify-center rounded-full border border-cove-blue p-1 text-cove-blue backdrop-blur">
            <Icon icon="ArrowLeft" />
            <span className="sr-only">Previous Slide</span>
          </div>
        </button>
        <motion.div
          key={carouselImages[0]}
          layoutId={carouselImages[0]}
          className="w-full"
        >
          <Imgix
            src={carouselImages[0]}
            htmlAttributes={{ alt: '' }}
            width={583}
            height={583}
            className="h-auto w-full"
          />
        </motion.div>
        <button
          className="absolute right-cove-10 top-1/2 z-10 flex size-9 -translate-y-1/2 items-center justify-center rounded-full bg-transparent  p-1 sm:right-cove-25"
          onClick={handleNextImage}
        >
          <div className="flex items-center justify-center rounded-full border border-cove-blue p-1 text-cove-blue backdrop-blur">
            <Icon icon="ArrowLeft" className="translate-y-0 rotate-180" />
            <span className="sr-only">Next Slide</span>
          </div>
        </button>
      </AspectRatio>

      <div className="mt-cove-5 flex justify-between gap-cove-5 sm:mt-cove-25 sm:gap-cove-15">
        {carouselImages
          .filter((_, index) => index !== 0)
          .map((image, index) => {
            return (
              <motion.button
                key={image}
                layoutId={image}
                className="w-full max-w-[100px]"
                onClick={() => goToSlide(index + 1)}
              >
                <AspectRatio>
                  <Imgix
                    src={image}
                    htmlAttributes={{ alt: '' }}
                    className="h-auto w-full"
                    width={583}
                    height={583}
                  />
                </AspectRatio>
              </motion.button>
            );
          })}
      </div>
    </div>
  );
}

const defaultImages = [
  'https://www.datocms-assets.com/10154/1743095650-preorder-hero-slide-1.png',
  'https://www.datocms-assets.com/10154/1743095651-preorder-hero-slide-2.png',
  'https://www.datocms-assets.com/10154/1743095650-preorder-hero-slide-3.png',
  'https://www.datocms-assets.com/10154/1743095651-preorder-hero-slide-4.png',
  'https://www.datocms-assets.com/10154/1743095651-preorder-hero-slide-5.png',
];
